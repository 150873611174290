<template>
  <div class="height-100">
    <loading v-if="loading"></loading>
    <div v-else>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-model="loading2" :finished="finished" finished-text="没有更多了" @load="nextPage">
          <goods-list-com style="margin-top: 0.8rem;" :DataList="zyData"> </goods-list-com>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import GoodsListCom from "../../components/GoodsListCom.vue";
export default {
  name: 'ProductList',
  data() {
    return {
      loading: true,
      loading2: false,
      finished: false, // 是否加载完了
      refreshing: false,
      isLoading: false,
      zyData: [],
      form: {
        cardId: '',
        current: 1, // 当前页码
        size: 10, // 每页个数
        brandName: '',
        cityCode: '',
        upgradeFlag: '',
        bathId: '',
      },
    }

  },
  created() { },
  components: { GoodsListCom },
  methods: {
    nextPage() { // 加载下一页
      if (this.zyData.length >= 10) {
        this.loading = false
        this.form.current += 1
        this.getData()
      }
    },
    // 刷新
    onRefresh() {
      this.form.current = 1
      this.getData();
      this.isLoading = false;
    },
    getData() {
      this.$api
        .queryProductInfoHomeNew(this.form)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          if (this.form.current == 1) {
            this.zyData = res.data.records
          } else {
            this.zyData.push(...res.data.records)
          }
          if (this.form.current >= res.data.pages) {
            this.finished = true
          }
          this.loading = false
          this.isLoading = false
          this.loading2 = false

        })
        .catch((err) => {
          console.log(err);
        });

    },
    // initData() {
    //   // if(this.$route.reflesh){

    //   // }
    //   this.form.cardId = localStorage.getItem('cardId')
    //   this.form.cityCode = localStorage.getItem("cityCode");
    //   this.form.upgradeFlag = localStorage.getItem("upgradeFlag");
    //   this.form.bathId = localStorage.getItem("bathId");
    //   this.form.brandName = this.$route.query.brandName;
    //   this.getData()
    //   this.zyData = []
    //   document.documentElement.scrollTop = 0;
    //   document.body.scrollTop = 0
    // }
  },
  watch: {
    
  },
  mounted() {
    this.entorcode = localStorage.getItem('entrCode')
    // 是否有手动选择地址
    this.address = localStorage.getItem("address");
    this.form.cardId = localStorage.getItem('cardId')
    this.form.cityCode = localStorage.getItem("cityCode");
    this.form.upgradeFlag = localStorage.getItem("upgradeFlag");
    this.form.bathId = localStorage.getItem("bathId");
    this.form.brandName = this.$route.params.brandName;
    this.getData()
  },
  inject: ["reload"],
  beforeRouteEnter(to, from, next) {
    if (from.path === "/Details") {
      next((vm) => {
        if (vm.$route.params.brandName !== vm.form.brandName) {
          vm.zyData = []
          vm.entorcode = localStorage.getItem('entrCode')
          // 是否有手动选择地址
          vm.address = localStorage.getItem("address");
          vm.form.cardId = localStorage.getItem('cardId')
          vm.form.cityCode = localStorage.getItem("cityCode");
          vm.form.upgradeFlag = localStorage.getItem("upgradeFlag");
          vm.form.bathId = localStorage.getItem("bathId");
          vm.form.brandName = vm.$route.params.brandName;
          vm.getData()
        }
        // vm.initData();
      });
    } else {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log('  beforeRouteLeave  pro ');
    next();
  },

}

</script>

<style lang="scss" scoped>
.container-water-fall {

  /* // padding: 0 28px; */
  padding: 0 3%;
  width: 100vw;
  box-sizing: border-box;
  /* background: #fafafa !important; */
}

.cell-item {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 5% 0;
}

.cell-item img {
  width: 100%;
  height: auto;
  display: block;
}
</style>